import { requiredDocuments_code } from './api'

export const ROOT_PATH = '/'

export const START_PATH = '/start'
export const SIGN_UP_PATH = '/sign_up'
export const LOGIN_PATH = '/login'
export const LOGIN_GOOGLE_PATH = `${LOGIN_PATH}/google`
export const LOGIN_MAIL_PATH = `${LOGIN_PATH}/mail`
export const LOGOUT_PATH = '/logout'
export const PASSWORD_PATH = '/password'
export const RESET_PASSWORD_NEW = '/reset_password/new'
export const RESET_PASSWORD_EDIT = '/reset_password/edit'
export const JOURNALS_PATH = '/journals'
export const INVOICES_PATH = '/invoices'
export const INVOICE_PATH = (invoiceId: number) => `/invoices/${invoiceId}`
export const REPORT_PATH = '/report'

// Jicoo完了画面
export const JICOO_RESERVATION_COMPLETE_PATH = '/reservation/complete'

// オンボーディング関連
export const ONBOARDING_PATH = '/onboarding'
export const QUESTION_PATH = `${ONBOARDING_PATH}/question`
export const PAYMENT_PATH = `${ONBOARDING_PATH}/payment`
export const RESERVATION_PATH = `${ONBOARDING_PATH}/reservation`
export const RESERVATION_COMPLETE_PATH = `${RESERVATION_PATH}/complete`
export const CONNECT_BANK_PATH = `${ONBOARDING_PATH}/connect_bank`

export const QUESTIONS_PATH = '/questions'

export const DEMO_PATH = '/demo'
export const TUTORIAL_PATH = '/tutorial'
export const PRICING_PATH = '/pricing'
export const ACTIVATE_PATH = '/activate'

export const PAYMENTS_PATH = '/payments'
export const PAYMENTS_COMPLETED_PATH = `${PAYMENTS_PATH}/completed`

export const PRICING_FREETRIAL_PATH = '/pricing_freetrial'
export const PRICING_AFTER_FREETRIAL_PATH = '/pricing_after_freetrial'
export const RESTART_PATH = '/restart'

export const TAX_TASK_PATH = (taxTaskId: number) => `/tax_tasks/${taxTaskId}`

export const INVITATION_PATH = '/invitation'
export const INVITATION_WELCOME_PATH = `${INVITATION_PATH}/welcome`
export const INVITATION_COMPLETED_PATH = `${INVITATION_PATH}/completed`

export const SETTINGS_PATH = '/settings'
export const TAX_RETURN_PATH = '/tax_return'
export const MARUNAGE_PATH = '/marunage'
export const ULTRA_PATH = '/ultra'
export const REAL_ESTATE_PATH = '/real_estate'

export const OPENING_BALANCE_PATH = `/opening_balance`

export const ZO_ADMIN_PATH = `/zo_admin`
export const TAX_ACCOUNTANT_PATH = `/tax_accountant`

export const PAGE_PATH = {
  export_books: {
    index: `/export_books`
  },
  // 管理者用の一年分まとめて表示するURL
  transactions_by_year: (year: number) => `/transactions/${year}`,
  transactions: (year: number, month: number) =>
    `/transactions/${year}/${month}`,
  tax_tasks: {
    index: `/`,
    detail: (taxTaskId: number) => `/tax_tasks/${taxTaskId}`,
    opening: {
      index: '/tax_tasks/opening'
    }
  },
  journals: {
    index: JOURNALS_PATH
  },
  tax_return: {
    basic_information: (year: number) =>
      `${TAX_RETURN_PATH}/${year}/basic_information`,
    income_and_expenditure_information: (year: number) =>
      `${TAX_RETURN_PATH}/${year}/income_and_expenditure_information`,
    confirm: (year: number) => `${TAX_RETURN_PATH}/${year}/confirm`,
    complete: (year: number) => `${TAX_RETURN_PATH}/${year}/complete`,
    submit: (year: number) => `${TAX_RETURN_PATH}/${year}/submit`,
    detail_edit: (year: number) => `${TAX_RETURN_PATH}/${year}/detail_edit`,
    final_check: (year: number) => `${TAX_RETURN_PATH}/${year}/final_check`,
    upgrade: `${TAX_RETURN_PATH}/upgrade`
  },
  marunage: {
    index: MARUNAGE_PATH,
    basic_information: `${MARUNAGE_PATH}/basic_information`,
    income_and_expenditure_information: `${MARUNAGE_PATH}/income_and_expenditure_information`,
    form: `${MARUNAGE_PATH}/form`
  },
  ultra: {
    sign_up: `${ULTRA_PATH}/sign_up`,
    login: `${ULTRA_PATH}/login`,
    pricing: `${ULTRA_PATH}/pricing`,
    activate: `${ULTRA_PATH}/activate`,
    index: ULTRA_PATH,
    preparing_for_the_first_meeting: `${ULTRA_PATH}/preparing_for_the_first_meeting`,
    basic_information: (year: number) =>
      `${ULTRA_PATH}/tax_return/${year}/basic_information`,
    income_and_expenditure_information: (year: number) =>
      `${ULTRA_PATH}/tax_return/${year}/income_and_expenditure_information`,
    form: (year: number) => `${ULTRA_PATH}/form/${year}`,
    confirm: (year: number) => `${ULTRA_PATH}/confirm/${year}`,
    chat: {
      index: `${ULTRA_PATH}/chat`,
      topics: (topicId: number) => `${ULTRA_PATH}/chat/${topicId}`
    },
    review: {
      index: `${ULTRA_PATH}/review`,
      journals: `${ULTRA_PATH}/review/journals`
    },
    tasks: {
      accounts: `${ULTRA_PATH}/tasks/accounts`,
      index: `${ULTRA_PATH}/tasks`,
      opening_date: `${ULTRA_PATH}/tasks/opening_date`,
      opening_balance: `${ULTRA_PATH}/tasks/opening_balance`,
      tax_return_type: `${ULTRA_PATH}/tasks/tax_return_type`,
      receipts: {
        index: `${ULTRA_PATH}/tasks/receipts`,
        detail: (receiptId: number) =>
          `${ULTRA_PATH}/tasks/receipts/${receiptId}`,
        deleted: {
          index: `${ULTRA_PATH}/tasks/receipts/deleted`,
          detail: (receiptId: number) =>
            `${ULTRA_PATH}/tasks/receipts/deleted/${receiptId}`
        }
      },
      upload_last_financial_statement: `${ULTRA_PATH}/tasks/upload_last_financial_statement`,
      upload_required_account_files: {
        index: `${ULTRA_PATH}/tasks/upload_required_account_files`,
        detail: (accountId: number) =>
          `${ULTRA_PATH}/tasks/upload_required_account_files/${accountId}`
      },
      startup_expenses: `${ULTRA_PATH}/tasks/startup_expenses`,
      medical_expenses: `${ULTRA_PATH}/tasks/medical_expenses`,
      taxable_type: `${ULTRA_PATH}/tasks/taxable_type`,
      e_tax: `${ULTRA_PATH}/tasks/e_tax`,
      required_documents: {
        index: `${ULTRA_PATH}/tasks/required_documents`,
        detail: (code: requiredDocuments_code) =>
          `${ULTRA_PATH}/tasks/required_documents/${code}`
      }
    },
    tax_return: (year: number) => {
      return {
        form: `${ULTRA_PATH}/tax_return/${year}/form`,
        confirm: `${ULTRA_PATH}/tax_return/${year}/confirm`,
        complete: `${ULTRA_PATH}/tax_return/${year}/complete`,
        submit: `${ULTRA_PATH}/tax_return/${year}/submit`,
        basic_information: `${ULTRA_PATH}/tax_return/${year}/basic_information`
      }
    }
  },
  real_estate: {
    sign_up: `${REAL_ESTATE_PATH}/sign_up`,
    login: `${REAL_ESTATE_PATH}/login`,
    pricing: `${REAL_ESTATE_PATH}/pricing`,
    activate: `${REAL_ESTATE_PATH}/activate`,
    index: REAL_ESTATE_PATH,
    tax_return: (year: number) => {
      return {
        form: `${REAL_ESTATE_PATH}/tax_return/${year}/form`,
        real_estate_form: `${REAL_ESTATE_PATH}/tax_return/${year}/form/real_estate`,
        confirm: `${REAL_ESTATE_PATH}/tax_return/${year}/confirm`,
        complete: `${REAL_ESTATE_PATH}/tax_return/${year}/complete`,
        submit: `${REAL_ESTATE_PATH}/tax_return/${year}/submit`,
        basic_information: `${REAL_ESTATE_PATH}/tax_return/${year}/basic_information`
      }
    },
    upload: `${REAL_ESTATE_PATH}/upload`,
    basic_information: (year: number) =>
      `${REAL_ESTATE_PATH}/tax_return/${year}/basic_information`,
    income_and_expenditure_information: (year: number) =>
      `${REAL_ESTATE_PATH}/tax_return/${year}/income_and_expenditure_information`
  },
  fixed_assets: {
    index: '/fixed_assets',
    detail: (assetId: number) => `/fixed_assets/${assetId}`
  },
  invoices: {
    index: `${INVOICES_PATH}`,
    create: `${INVOICES_PATH}/create`,
    images: {
      detail: (imageId: number) => `${INVOICES_PATH}/images/${imageId}`,
      deleted: `${INVOICES_PATH}/images/deleted`
    }
  },
  report: {
    index: `${REPORT_PATH}`
  },
  opening_balance: {
    index: OPENING_BALANCE_PATH,
    detail: `${OPENING_BALANCE_PATH}/detail`
  },
  demo: {
    index: DEMO_PATH,
    tax_tasks_all: `${DEMO_PATH}/tax_tasks_all`
  },
  payments: PAYMENTS_PATH,
  onboarding: {
    payment: PAYMENT_PATH
  },
  activate: ACTIVATE_PATH,
  pricing: PRICING_PATH,
  pricing_freetrial: PRICING_FREETRIAL_PATH,
  pricing_after_freetrial: PRICING_AFTER_FREETRIAL_PATH,
  restart: RESTART_PATH,
  questions: {
    pre_service: `${QUESTIONS_PATH}/pre-service`,
    ultra_initial_servey: `${QUESTIONS_PATH}/ultra_initial_servey`
  },
  taxes: `/taxes`,
  taxable_type: `/taxable_type`,
  tax_accountant: {
    sign_in: `${TAX_ACCOUNTANT_PATH}/sign_in`,
    password: `${TAX_ACCOUNTANT_PATH}/password`,
    reset_password: {
      new: `${TAX_ACCOUNTANT_PATH}/reset_password/new`,
      edit: `${TAX_ACCOUNTANT_PATH}/reset_password/edit`
    },
    users: {
      index: `${TAX_ACCOUNTANT_PATH}/users`,
      documents: (id: number) => `${TAX_ACCOUNTANT_PATH}/users/${id}/documents`,
      user_states: (id: number) =>
        `${TAX_ACCOUNTANT_PATH}/users/${id}/user_states`,
      journal: (id: number) => `${TAX_ACCOUNTANT_PATH}/users/${id}/journals`
    }
  },
  zo_admin: {
    ip_addresses: {
      index: `${ZO_ADMIN_PATH}/ip_addresses`
    },
    tax_accountants: {
      index: `${ZO_ADMIN_PATH}/tax_accountants`,
      create: `${ZO_ADMIN_PATH}/tax_accountants/create`,
      detail: (taxAccountId: number) =>
        `${ZO_ADMIN_PATH}/tax_accountants/${taxAccountId}`
    },
    ultra: {
      index: `${ZO_ADMIN_PATH}/ultra`,
      user_states: {
        details: (userId: number) => {
          const path = `${ZO_ADMIN_PATH}/ultra/user_states/${userId}`
          return {
            read: `${path}`,
            edit: `${path}/edit`
          }
        }
      },
      journal_notes: {
        details: (userId: number) => {
          return `${ZO_ADMIN_PATH}/ultra/journal_notes/${userId}`
        }
      },
      receipts: {
        index: `${ZO_ADMIN_PATH}/ultra/receipts`,
        detail: (receiptId: number) =>
          `${ZO_ADMIN_PATH}/ultra/receipts/${receiptId}`
      },
      chat: {
        index: `${ZO_ADMIN_PATH}/ultra/chat`,
        user: (userId: number) => `${ZO_ADMIN_PATH}/ultra/chat/${userId}/`,
        topics: (userId: number, topicId: number) =>
          `${ZO_ADMIN_PATH}/ultra/chat/${userId}/${topicId}`
      },
      statement_files: {
        index: `${ZO_ADMIN_PATH}/ultra/statement_files`,
        detail: (accountId: number) =>
          `${ZO_ADMIN_PATH}/ultra/statement_files/${accountId}`
      }
    }
  }
} as const

export const TERMS_PATH =
  'https://thoughtful-argon-5a8.notion.site/c6c76467012046cebec0833c830da7e4'
export const PRIVACY_POLICY_PATH =
  'https://thoughtful-argon-5a8.notion.site/c1a2ad385b614a97a516c89c51806de0'
export const ABOUT_INVITATION_CAMPAIGN_PATH =
  'https://thoughtful-argon-5a8.notion.site/602b869a06ed4b1880d45841bafc98ef'
